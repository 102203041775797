import styled from 'styled-components'

export const TransparentButton = styled.button`
  outline: none;
  padding: 5px 12px;

  border: 1px solid #ffffff;
  border-radius: 29px;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.color.white}
  ${props => props.theme.withTransition}

  &:active,
  &:focus {
    outline: none;
  }

  &:active {
    opacity: 0.8;
  }

  &:hover {
    ${props => props.theme.withZoom}
  }

  ${props => props.theme.media.lessThan('tablet')`
  font-size: 12px;
  padding: 6px 9px;
`}
`
export const YellowButton = styled(TransparentButton)`
  border-radius: 11px;
  box-shadow: 10px 20px 40px #7e71284e;
  background: #f2c222 0% 0% no-repeat padding-box;
  border: none;
  font-size: 14px;
  font-weight: bold;
`
