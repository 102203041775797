import React from 'react'
import styled from 'styled-components'
import useTranslation from '@hooks/useTranslation'
import { YellowButton } from '@common/buttons/commonButtons'

const SuccessWrapper = styled.div`
  margin: 30vh auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 70px 0px;
`

const LinkToNewPage = styled.a`
  dispaly: block;
`

const GoToButton = styled(YellowButton)`
  border-radius: 11px;
  box-shadow: 10px 20px 40px #7e71284e;
  background: #f2c222 0% 0% no-repeat padding-box;
  border: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
`

const IndexPage = () => {
  const { labelStrings } = useTranslation()

  return (
    <SuccessWrapper>
      {labelStrings.webinarOct2020.successPurchase}
      <LinkToNewPage href={`${process.env.WECREATE_HOST}`} target='_blank' rel='noreferrer'>
        <GoToButton>{labelStrings.webinarOct2020.about.link}</GoToButton>
      </LinkToNewPage>
    </SuccessWrapper>
  )
}

export default IndexPage
